*{
  box-sizing: border-box;
}

main{
  margin: auto;
  height: 100%;
    background-image: url("./images/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding-top: 10px;
}

a{
  cursor: pointer;
}
image{
  max-width: 100%;
}

header{
  display: flex;
  align-items: center;
  max-width: 1000px;
  max-height: 200px;
  margin: auto;
  margin-bottom: 75px;
}

.title-break{
  visibility: hidden;
}

header a{
  text-decoration: none;
  color: black;
}

header a.logo{
  margin-left: 5px;
  max-width: 330px;
  margin-right: auto;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
}

header nav{
  display: flex;
  padding: 16px;
  gap: 20px;
}



footer {
  display: flex;
  justify-content: center;
  min-height: 300px;
  margin-top: 100px;
  position: relative;
  background-image: url("./images/footer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.footer-info{
  display: flex;
  padding-bottom: 10px;
  max-width: 1000px;
  align-self: flex-end;
}

footer .admin-link{
  text-decoration: none;
  color: black;
  max-width: 1000px;
  padding-top: 100px;
  padding-left: 30px;
  text-align: right;
}

div .post{
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 600px;
}

div.post img{
  display: block;
  width: 60%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

form.login{
  max-width: 500px;
  margin: 0 auto;
  margin-top: 200px;
}

form.login h2{
  text-align: center;
}

input{
  display: block;
  margin-bottom: 5px;
  flex-shrink: auto;
  width: 100%;
  padding: 5px 7px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

button{
  cursor: pointer;
  width: 100%;
  display: block;
  background-color: #555;
  border-radius: 8px;
  padding: 5px;
  color: white;
  font-weight: 600;
}

select{
  margin-bottom: 10px;
  cursor: pointer;
}

form.create-post{
  max-width: 700px;
  margin: 0 auto;
}

.post-btns{
  display:inline-flex;
  align-items: center;
}

a.edit-btn{
  display: inline-flex;
  background-color: #333;
  align-items: center;
  gap: 5px;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  text-decoration: none;
  max-height: 50px;
  white-space: nowrap;
}

button.delete-btn{
    display: inline-flex;
      background-color: #F25F5C;
      align-items: center;
      gap: 5px;
      color: #fff;
      margin-left: 5px;
      padding: 5px;
      border-radius: 5px;
      text-decoration: none;
      max-width: 50px;
      max-height: 40px;
      white-space: nowrap;
}

a svg{
  height: 20px;
}

.title-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 40px;
  margin-bottom: 10px;
}

.staff-post .title-row h2{
  margin-left: 30px;
}

.staff-post{
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 30px;
  max-width: 1000px;
}

.staff-post .post-content{
  display: flex;
  align-items: flex-start;
}

.staff-post img{
  padding: 30px;
  max-width: 230px;
  left: auto;
}


.about-post{
  display: flex;
  flex-direction: column;
  margin:auto;
  margin-bottom: 30px;
  max-width: 1000px;
}

.about-post .postImage{
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.about-post .title-row{
  display: flex;
  align-self: center;
}


.treatment-post{
  display: flex;
  flex-direction: column;
  margin:auto;
  margin-bottom: 30px;
  max-width: 1000px;
}

.treatment-post .postImage{
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.treatment-post .title-row{
  align-self: center;
}

.faq-post{
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 30px;
  max-width: 1000px;
}

.faq-post .postImage{
  display: flex;
  justify-content: center;
  max-width: 0px;
  max-height: 0px;
}

.faq-post .title-row h2{
  margin-left: 0;
}

th {
  text-align: left;
  font-size:larger;
}

td{
  padding: 0 15px;
  font-size:larger;
}

.location{
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 1000px;
  margin: auto;
}

.location .contact{
  margin-left: 80px;
}

.map{
  display: flex;
  flex-direction: column;
  align-self: center; 
  max-width: 1000px;
  margin: auto;
}

.map h2{
  align-self: center;
}

.map-info{
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
}

.map-info p{
  font-size: larger;
  align-self: center;
}
     
.map iframe{
    border: 0;
    height: 200px;
    width: 500px; 
    padding-left: 20px; 
    padding-top: 5px;
}


@media(max-width:768px){

  header {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  header a.logo {
    justify-self: center;
    text-align: center;
    margin: auto;
  }

  a.logo{
    justify-self: center;
  }



  .title-break{
    visibility: visible;
  }

  header nav {
    display: flex;
    padding: 16px;
  }

  .about-post{
    padding-left: 2%;
    padding-right: 2%;
  }

  .about-post .postImage{
     max-width: 100%;
      max-height: 100vh;
      margin: auto;
  }

  .staff-post{
    padding-left: 4%;
    padding-right: 4%;
    align-items: center;
  }

  .staff-post .post-content{
    flex-direction: column;
    align-items: center;
  }
  
  .staff-post .title-row{
    margin-bottom: 0px;
  }

  .staff-post .title-row h2{
    align-self: center;
    margin-left: 0px;
  }


  .faq-post{
    padding-left: 2%;
    padding-right: 2%;
  }

  .treatment-post{
    padding-left: 2%;
    padding-right: 2%;
  }

  .location{
    flex-direction: column;
    align-items: center;
  }

  .location h2{
    align-self: center;
    text-align: center;
  }

  .location .contact{
    margin-left: 0;
  }

    .map iframe{
      align-self: center;
      border: 0;
      height: 200px;
      width: 80%;
      padding-left: 20px;
      padding-top: 5px;
    }

    .map-info p{
      width:80%;
      text-align: center;
    }

}

